import { css } from '@emotion/react';
import React, { memo } from 'react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useSnapshot } from 'valtio';
import { FreemanSymbolChart } from './component/FreemanSymbolChart';
import { InfoContent } from './component/InfoContent';
import { WatchListTrendChartContent } from './component/WatchListTrendChartContent';
import { freemanStore } from './freemanStore';
import { estimatedVolumeStore } from './component/EstimatedVolume';
import { EstimatedVolumeContent } from './component/EstimatedVolumeContent';
const TrendChartContent = memo(function TrendChartContent() {
    const state = useSnapshot(freemanStore);
    const data = state.popularSymbols.slice(0, 10);
    return (<div css={css `
        ${classes.body};
        grid-template-columns: repeat(${4}, 1fr);
        gap: 6px;
      `}>
      {data.map(quote => {
            return (<FreemanSymbolChart key={quote.symbol} symbol={quote.symbol}/>);
        })}
    </div>);
});
export const Freeman_Row = memo(function Freeman_Row() {
    const state = useSnapshot(freemanStore);
    const estimatedValue = useSnapshot(estimatedVolumeStore).value;
    const estimatedPrefValue = useSnapshot(estimatedVolumeStore).prefValue;
    const estimatedVolume = estimatedValue / 1e8;
    const estimatedPrefVolume = estimatedPrefValue / 1e8;
    return (<div css={classes.container}>
      <div css={classes.header}>
        <span css={state.rowPageType === 'info' ? classes.titleSelected : classes.title} onClick={() => (freemanStore.rowPageType = 'info')}>
          台指盤中資訊
        </span>
        <span css={state.rowPageType === 'chart' ? classes.titleSelected : classes.title} onClick={() => (freemanStore.rowPageType = 'chart')}>
          熱門股走勢圖
        </span>
        <span css={state.rowPageType === 'watchList' ? classes.titleSelected : classes.title} onClick={() => (freemanStore.rowPageType = 'watchList')}>
          自選股走勢圖
        </span>
        <span css={state.rowPageType === 'estimatedVolume' ? classes.titleSelected : classes.title} onClick={() => (freemanStore.rowPageType = 'estimatedVolume')}>
          大盤預估量
        </span>
      </div>
      {state.rowPageType === 'chart' && (<div css={css `
            ${classes.body};
          `}>
          <TrendChartContent />
        </div>)}
      {state.rowPageType === 'watchList' && (<div css={css `
            ${classes.body};
          `}>
          <WatchListTrendChartContent />
        </div>)}
      {state.rowPageType === 'info' && (<div css={css `
            ${classes.body};
          `}>
          <InfoContent />
        </div>)}
      {state.rowPageType === 'estimatedVolume' && (<div css={css `
            ${classes.body};
          `}>
          <EstimatedVolumeContent />
        </div>)}
    </div>);
});
const classes = {
    container: css `
    ${fill_vertical_cross_center};
    background-color: #151515;
    padding: 4px;
    gap: 2px;
  `,
    header: css `
    ${fill_horizontal_cross_center};
    height: 28px;
    gap: 4px;
    align-items: end;
  `,
    body: css `
    ${scrollbar2Css};
    display: grid;
    max-width: 100%;
    height: calc(100% - 30px);
    background-color: #191919;
    border: 1px solid #888888;
    border-radius: 4px;
  `,
    title: css `
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #232323;
    color: #aaaaaa;
    border-radius: 4px;
    padding: 0 8px;
    cursor: pointer;
  `,
    titleSelected: css `
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #545454;
    border-radius: 4px;
    padding: 0 8px;
    cursor: pointer;
  `,
};
